import { createApp, Directive, DirectiveBinding, VNode } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

import { store } from './store';
import i18n from './i18n';

import { es } from 'date-fns/locale'
import { setDefaultOptions } from 'date-fns'
setDefaultOptions({
  locale: es,
  'weekStartsOn': 1,
})

// Animations
import 'animate.css';
export const appear: Directive = {
  beforeMount(element: HTMLElement) {
    element.style.visibility = 'hidden';
  },
  updated(element: HTMLElement, binding: DirectiveBinding<boolean>, node: VNode) {
    if (!binding.value === !binding.oldValue || null === node.transition) {
      return;
    }

    if (!binding.value) {
      node.transition.leave(element, () => {
        element.style.visibility = 'hidden';
      });
      return;
    }

    node.transition.beforeEnter(element);
    element.style.visibility = '';
    node.transition.enter(element);
  }
};

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import '../public/assets/css/custom.scss';
import '../public/assets/css/unifiedUI.scss';

const app = createApp(App)
  .use(i18n)
  .use(IonicVue)
  .use(store)
  .use(router);
  
router.isReady().then(() => {
  app.directive('appear', appear)
  .mount('#app');
});