<template>
  <ion-menu content-id="main-content" menu-id="main-menu" class="main-menu" :swipeGesture="false" side="start">
    <ion-content style="--background: var(--ion-color-light-tint)">
        <div class="container">
            <ion-header class="ion-no-border" id="menu-header">
            <ion-toolbar style="--background: var(--ion-color-light-tint)" class="menu-header-item">
                <a href="/" class="logo" slot="start" v-if="miniweb">
                    <img :src="miniweb.information.logo" :alt="miniweb.information.name"/>
                </a>

                <ion-buttons slot="end">
                    <ion-button @click="close()">
                        <ion-icon slot="icon-only" src="/assets/img/uicons/fi-rr-cross-small.svg"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
            </ion-header>

            <ion-list lines="none" class="menu-nav">
                <ion-menu-toggle>
                    <ion-item button @click="scrollToSection('servicios')" detail="false">
                        <ion-label>Productos</ion-label>
                        <ion-icon slot="end" src="/assets/img/uicons/fi-rr-angle-small-right.svg"></ion-icon>
                    </ion-item>  
                </ion-menu-toggle>

                <ion-menu-toggle>
                    <ion-item button @click="scrollToSection('sobre')" detail="false">
                        <ion-label>Tiendas</ion-label>
                        <ion-icon slot="end" src="/assets/img/uicons/fi-rr-angle-small-right.svg"></ion-icon>
                    </ion-item>  
                </ion-menu-toggle>

                <ion-menu-toggle>
                    <ion-item button @click="scrollToSection('sobre')" detail="false">
                        <ion-label>Sobre Nosotros</ion-label>
                        <ion-icon slot="end" src="/assets/img/uicons/fi-rr-angle-small-right.svg"></ion-icon>
                    </ion-item>  
                </ion-menu-toggle>

                <ion-menu-toggle>
                    <ion-item button @click="scrollToSection('testimonios')" detail="false">
                        <ion-label>Reseñas</ion-label>
                        <ion-icon slot="end" src="/assets/img/uicons/fi-rr-angle-small-right.svg"></ion-icon>
                    </ion-item>  
                </ion-menu-toggle>

                <ion-menu-toggle>
                    <ion-item button @click="scrollToSection('galeria')" detail="false">
                        <ion-label>Galería</ion-label>
                        <ion-icon slot="end" src="/assets/img/uicons/fi-rr-angle-small-right.svg"></ion-icon>
                    </ion-item>  
                </ion-menu-toggle>
            </ion-list>

            <ion-button slot="end" shape="round" expand="block" class="action-button" @click="openContact()">
                <ion-label>Contactar</ion-label>
            </ion-button>

            <ion-buttons slot="end" class="social-icons" v-if="miniweb">
                <ion-button v-for="social in miniweb.socials" :key="social" :href="social.url" target="_blank">
                    <ion-icon slot="icon-only" :src="social.icon"></ion-icon>
                </ion-button>
            </ion-buttons>
        </div>
    </ion-content>
  </ion-menu>
</template>


<script>
import { defineComponent } from 'vue';
import { IonMenu, IonContent, IonHeader,IonToolbar, IonButtons, IonButton, IonIcon, IonList, IonItem, IonLabel, menuController, IonMenuToggle } from '@ionic/vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'MenuComponent',
  components: { IonMenu, IonContent, IonHeader,IonToolbar, IonButtons, IonButton, IonIcon, IonList, IonItem, IonLabel, IonMenuToggle },
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['miniweb'])
  },
  methods: {
    close(){
        menuController.close();
    },

    async scrollToSection(section){
        const navigationResult = await this.$router.push({ path: `/` })

            if (navigationResult) {
            const scrollContent = document.querySelector('ion-content.main-content');
            let y = document.getElementById(section).offsetTop;
            scrollContent.scrollToPoint(0, y - 64, 500)
            } else {
            setTimeout(() => {
                const scrollContent = document.querySelector('ion-content.main-content');
                let y = document.getElementById(section).offsetTop;
                scrollContent.scrollToPoint(0, y - 64, 500)
            }, 100);

        }
    },

    openContact(){
        menuController.open('contact-menu');
    }
  },
});
</script>

<style scoped>
    .main-menu{
        --width: 100%;
        --max-width: 400px;
        --background: var(--ion-color-light-tint);
    }

    #menu-header{
        overflow: visible;
        background: transparent;
        border-bottom: 0;
        margin: 24px 0;
    }

    .menu-header-item{
        --inner-padding-start: 0;
        --inner-padding-end: 0;
        --inner-padding-top: 0;
        --inner-padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;
        display: flex;
        align-items: center;
        height: 48px;
        overflow: visible;
        margin: 0 auto;
    }

    .logo{
        display: block;
        cursor: pointer;
        width: auto;
        height: 48px;
        margin: 0;
    }

    .logo img{
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center left;
    }

    .menu-nav{
        margin: 24px 0;
    }

    .menu-nav ion-item{
        border-bottom: 1px solid var(--ion-color-light-shade);
        font-size: 16px;
        font-weight: 700;
        --padding-top: 4px;
        --padding-bottom: 4px;
    }

    .social-icons{
        position: absolute;
        bottom: 24px;
    }
  

    @media (min-width: 720px) and (max-width: 960px) {
    }

    @media (min-width: 960px) and (max-width: 1140px) {
    }

    @media (min-width: 1140px) {
    }
    
</style>