import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBO8VspS6YAn0sXp9r0OWzGoHr8i3sJPa8",
  authDomain: "mini-web-8bbb0.firebaseapp.com",
  projectId: "mini-web-8bbb0",
  storageBucket: "mini-web-8bbb0.appspot.com",
  messagingSenderId: "418747565670",
  appId: "1:418747565670:web:c0488737ac6e90e7d0cfa7",
  measurementId: "G-W1YRSP9KGX"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore();
firebaseApp.firestore().settings({
  ignoreUndefinedProperties: true,
});
