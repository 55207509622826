
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';

import Menu from '@/components/Menu.vue';
import Contact from '@/views/Contact.vue';

export default defineComponent({
  name: 'App',
  components: { IonApp, IonRouterOutlet, Menu, Contact }
});
