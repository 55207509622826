<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-events="true" class="main-content">

      <div id="fireworks-container"></div>

      <div class="container" v-if="miniweb">
        <Header activePage="Servicios"></Header>

        <section id="slider" class="no-container mb-xl" v-if="miniweb.slider && miniweb.slider.length">
          <AnimatedComponent animation-enter="animate__fadeIn">
            <swiper class="swiper-imagenes" :modules="modules" :navigation="false" :pagination="false" :slidesPerView="1"  :autoplay="miniweb.slider.length ? false : true" :spaceBetween="24" :loop="false" :grabCursor="true">
              <swiper-slide v-for="slide in miniweb.slider.sort((a, b) => { a.order > b.order } )" :key="slide">
                <div class="swiper-imagen">
                  
                </div>
                <div class="swiper-info">
                  <h1 class="title">{{slide.title}}</h1>
                  <h5 class="subtitle">{{slide.subtitle}}</h5>
                  <div class="actions" style="margin-top: 16px">
                    <ion-button shape="round" style="margin: 8px" @click="scrollToSection('servicios')">
                      <ion-label>Ofertas</ion-label>
                    </ion-button>
                    <ion-button @click="openContact()" fill="outline" color="dark" shape="round" style="margin: 8px">
                      <ion-label>Contactar</ion-label>
                    </ion-button>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </AnimatedComponent>
        </section>

        <section id="servicios" class="mb-xxl" v-if="miniweb.products && miniweb.products.length">
          <ion-grid>
            <ion-row class="mb-sm">
              <ion-col size="12">
                <h2>Nuestro catálogo</h2>
                <h4 class="color-medium normal pt-xs">Descubre todas nuestras ofertas</h4>
              </ion-col>
            </ion-row>           
            <ion-row class="mb-xs">
              <ion-col size="12" size-md="8" size-lg="8" style="align-items: center; display: flex;">
                <ion-segment mode="ios" :scrollable="true" :value="activeCategory" class="categories" @ionChange="filterProducts($event)">
                  <ion-segment-button value="Novedades" class="categories-item" id="cat0">
                    <ion-label>Novedades</ion-label>
                  </ion-segment-button>
                  <ion-segment-button value="Truenos" class="categories-item" id="cat1">
                    <ion-label>Truenos</ion-label>
                  </ion-segment-button>
                  <ion-segment-button value="Tracas" class="categories-item" id="cat2">
                    <ion-label>Tracas</ion-label>
                  </ion-segment-button>
                  <ion-segment-button value="Efectos Tierra y Aire" class="categories-item" id="cat3">
                    <ion-label>Efectos Tierra y Aire</ion-label>
                  </ion-segment-button>
                  <ion-segment-button value="Infantil" class="categories-item" id="cat3">
                    <ion-label>Infantil</ion-label>
                  </ion-segment-button>
                  <ion-segment-button value="Cohetes" class="categories-item" id="cat3">
                    <ion-label>Cohetes</ion-label>
                  </ion-segment-button>
                  <ion-segment-button value="Baterías" class="categories-item" id="cat3">
                    <ion-label>Baterías</ion-label>
                  </ion-segment-button>
                  <ion-segment-button value="Fuentes" class="categories-item" id="cat3">
                    <ion-label>Fuentes</ion-label>
                  </ion-segment-button>
                  <ion-segment-button value="Humos" class="categories-item" id="cat3">
                    <ion-label>Humos</ion-label>
                  </ion-segment-button>
                </ion-segment>
              </ion-col>
              <ion-col size="12" size-md="1" size-lg="1"></ion-col>
              <ion-col size="12" size-md="3" size-lg="3">
                <ion-select class="order" interface="popover" mode="md" placeholder="Seleccionar" @ionChange="sortProducts($event)" :value="activeSort" :interface-options="{cssClass: 'select'}">
                  <ion-select-option value="populares">Populares</ion-select-option>
                  <ion-select-option value="bajo">Precio más bajo</ion-select-option>
                  <ion-select-option value="alto">Precio más alto</ion-select-option>
                </ion-select>
              </ion-col>
            </ion-row>
            <ion-row class="services">
              <ion-col size="6" size-md="4" size-lg="3" v-for="product in products" :key="product">
                <AnimatedComponent animation-enter="animate__fadeIn">
                  <div button class="service" @click="openDetails(product)">
                    <div class="ion-activatable ripple-parent">
                      <img :class="(product.order < 1) ? 'image-full' : 'image'" :src="product.images[0][512]" :alt="miniweb.information.name" />
                      <ion-ripple-effect></ion-ripple-effect>
                    </div>
                    
                    <div class="text">
                      <h5 class="title">{{product.name}}</h5>
                      <p class="price" v-if="product.price && product.discount"><span class="discount">{{product.price.toFixed(2)}}€</span>{{(product.price * (100 - product.discount) / 100 ).toFixed(2)}}€</p>
                      <p class="price" v-if="product.price && !product.discount">{{product.price.toFixed(2)}}€</p>
                    </div>

                  </div>
                </AnimatedComponent>
              </ion-col>
            </ion-row>
            <ion-row v-if="products.length < productsData.length">
              <ion-col size="12">
                <ion-button shape="round" @click="loadMoreData" fill="outline" style="margin:0 auto; margin-top: 48px; display: table;" color="medium">
                  <ion-label>Ver más</ion-label>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </section>

        <section id="catalogo" class="mb-xxl" v-if="miniweb.products && miniweb.products.length">
          <ion-grid>
            <ion-row class="mb-sm">
              <ion-col size="12" style="text-align: center;">
                <h2>Descarga nuestro catálogo completo</h2>
                <ion-button shape="round" style="margin-top: 16px" target="_blank" href="https://firebasestorage.googleapis.com/v0/b/mini-web-8bbb0.appspot.com/o/pirovampiro.es%2FPIROVAMPIRO-2023.pdf?alt=media">
                  <ion-label>Descargar PDF</ion-label>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </section>

        <section id="sobre" class="mb-xxl" v-if="miniweb.about">
          <ion-grid>
            <ion-row>
              <ion-col size="12" size-lg="5">
                <AnimatedComponent animation-enter="animate__fadeIn">
                  <img class="about-image" :src="miniweb.about.image[1024]" :alt="miniweb.information.name"/>
                </AnimatedComponent>
              </ion-col>
              <ion-col size-lg="1"></ion-col>
              <ion-col size="12" size-lg="6">
                <div class="about-text">
                  <AnimatedComponent animation-enter="animate__fadeIn">
                    <h2 class="pb-sm">{{miniweb.about.title}}</h2>
                    <p class="about-paragraph" v-html="miniweb.about.text"></p>
                  </AnimatedComponent>

                  <ion-list lines="none" class="mt-md">
                    <ion-item style="border-bottom: 1px solid var(--ion-color-light-tint)" class="pb-xs">
                      <ion-label text-wrap>
                        <h4 class="color-tertiary">Chiclana</h4>
                        <h5 class="color-medium pt-xxs">Centro comercial Las Redes<br>Frente a McDonald's</h5>
                      </ion-label>
                      <ion-button slot="end" shape="round" size="small" href="https://goo.gl/maps/jnrb4MbKt4aEdLZGA" target="_blank">
                        <ion-label>Más Información</ion-label>
                      </ion-button>
                    </ion-item>
                    <ion-item style="border-bottom: 1px solid var(--ion-color-light-tint)"  class="pt-xs pb-xs">
                      <ion-label text-wrap>
                        <h4 class="color-tertiary">Los Barrios</h4>
                        <h5 class="color-medium pt-xxs">Guadacorte<br>Junto al Hotel Guadacorte</h5>
                      </ion-label>
                      <ion-button slot="end" shape="round" size="small" href="https://goo.gl/maps/oLBbUnx517MkHWzr9" target="_blank">
                        <ion-label>Más Información</ion-label>
                      </ion-button>
                    </ion-item>
                    <ion-item class="pt-xs">
                      <ion-label text-wrap>
                        <h4 class="color-tertiary">Algeciras</h4>
                        <h5 class="color-medium pt-xxs">Rotonda el Saladillo<br>Junto a Puertas Marco</h5>
                      </ion-label>
                      <ion-button slot="end" shape="round" size="small" href="https://goo.gl/maps/LrpwPHctTv9JpMZz7" target="_blank">
                        <ion-label>Más Información</ion-label>
                      </ion-button>
                    </ion-item>
                  </ion-list>

                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </section>

        <section id="caracteristicas" class="mb-xxl" v-if="miniweb.features && miniweb.features.length">
          <ion-grid>
            <ion-row class="caracteristicas-container">
              <ion-col size="12" size-sm="6" size-lg="4" v-for="feature in miniweb.features.sort((a, b) => { a.order > b.order } )" :key="feature">
                <AnimatedComponent animation-enter="animate__fadeIn">
                  <ion-card class="feature">
                    <ion-card-content>
                      <img class="icon" :src="feature.icon" :alt="miniweb.information.name"/>
                      <h4 class="title">{{feature.title}}</h4>
                      <p class="description">{{feature.text}}</p>
                    </ion-card-content>
                  </ion-card>
                </AnimatedComponent>
              </ion-col>
            </ion-row>
          </ion-grid>
        </section>

        <section id="galeria" class="mb-xxl" v-if="miniweb.gallery && miniweb.gallery.length">
          <ion-grid>
            <ion-row class="mb-md">
              <ion-col size="12">
                <h2>Galería</h2>
                <h4 class="color-medium normal pt-xs">Pirotecnia para celebrar esos momentos especiales.</h4>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <div class="mansory-gallery">
                  <div class="mansory-img" v-for="(image, i) in miniweb.gallery" :key="i" >
                    <AnimatedComponent animation-enter="animate__fadeIn">
                      <img :src="image.src" :alt="miniweb.information.name" />
                    </AnimatedComponent>
                  </div>
                </div>
             </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-button shape="round" style="margin:0 auto; margin-top: 48px; display: table;" color="primary" target="_blank" href="https://www.instagram.com/pirovampiro_cadiz/">
                  <ion-icon slot="start" src="/assets/img/uicons/fi-brands-instagram.svg" style="margin-right: 12px; color: var(--ion-color-primary-contrast);"></ion-icon>
                  <ion-label>Síguenos en Instagram</ion-label>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </section>

        <section id="testimonios" class="no-container mb-xxl" v-if="miniweb.testimonials && miniweb.testimonials.length">
          <swiper class="swiper-testimonials" :modules="modules" :spaceBetween="136" :pagination="false" :navigation="true" :slidesPerView="1" :autoplay="true" :loop="true" :grabCursor="true"
          :breakpoints="{
                                '540': {
                                  navigation: false,
                                },
                                '720': {
                                  navigation: false,
                                },
                              }">
            <swiper-slide v-for="testimonial in miniweb.testimonials" :key="testimonial.author">
              <AnimatedComponent animation-enter="animate__fadeIn">
                <div class="testimonial">
                  <ion-grid>
                    <ion-row>
                      <ion-col size="12" size-lg="2">
                        <ion-thumbnail class="avatar">
                          <img :src="testimonial.avatar" :alt="miniweb.information.name"/>
                        </ion-thumbnail>
                      </ion-col>
                      <ion-col size="12" size-lg="10">
                        <p class="comment">{{testimonial.comment}}</p>
                      </ion-col>
                    </ion-row>

                    <ion-row>
                      <ion-col size="12" size-lg="2"></ion-col>
                      <ion-col size="12" size-lg="5">
                        <h5 class="author">{{testimonial.author}}</h5>
                        <star-rating class="stars" :rating="5" read-only :star-size="20" :show-rating="false"></star-rating>
                      </ion-col>
                      <ion-col size="12" size-lg="5">
                        <img class="provider" src="https://www.edigitalagency.com.au/wp-content/uploads/google-my-business-logo-png-transparent.png" :alt="miniweb.information.name"/>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </div>
              </AnimatedComponent>
            </swiper-slide>
          </swiper>

          <ion-button shape="round" class="more-testimonials" fill="outline" color="dark" href="https://goo.gl/maps/jnrb4MbKt4aEdLZGA" target="_blank">
            <ion-icon slot="start" src="/assets/img/uicons/fi-brands-google.svg" style="margin-right: 12px; color: var(--ion-color-primary-contrast);"></ion-icon>
            <ion-label>Ver mas reseñas en Google</ion-label>
          </ion-button>
        </section>

        <section id="preguntas" class="mb-xxl" v-if="miniweb.faqs && miniweb.faqs.length">
          <ion-grid>
            <ion-row class="mb-sm">
              <ion-col size="12">
                <h2>Preguntas Frecuentes</h2>
                <h4 class="color-medium normal pt-xs">Resolvemos vuestras dudas</h4>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-accordion-group class="faqs">
                  <ion-accordion v-for="(faq, i) in miniweb.faqs.sort((a, b) => { a.order > b.order } )" :key="faq" :value="i">
                    <ion-item slot="header" color="transparent" lines="none">
                      <ion-label text-wrap><h5>{{faq.question}}</h5></ion-label>
                    </ion-item>
                    <div class="pb-xs" slot="content">
                      <p v-html="faq.answer"></p>
                    </div>
                  </ion-accordion>              
                </ion-accordion-group>
              </ion-col>
            </ion-row>
          </ion-grid>
        </section>

        <!--<section id="noticias" class="mb-xxl">
          <ion-grid>
            <ion-row class="mb-md">
              <ion-col size="12" size-lg="9">
                <h2>Novedades</h2>
                <h4 class="color-medium normal pt-xs">Sigue nuestros eventos y noticias</h4>
              </ion-col>
              <ion-col size="12" size-lg="3" class="hide-xs hide-sm hide-md">
                <ion-buttons style="justify-content: flex-end;">
                  <ion-button>
                    <ion-icon slot="icon-only" src="/assets/img/uicons/fi-rr-angle-left.svg"></ion-icon>
                  </ion-button>
                  <ion-button>
                    <ion-icon slot="icon-only" src="/assets/img/uicons/fi-rr-angle-right.svg"></ion-icon>
                  </ion-button>
                </ion-buttons>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <swiper class="swiper-noticias swiper-h" :modules="modules" :navigation="false" :slidesPerView="1.25" :freeMode="false" :spaceBetween="16" :autoplay="false" :loop="false"
                :breakpoints="{
                                '540': {
                                  slidesPerView: 2,
                                  spaceBetween: 16,
                                },
                                '720': {
                                  slidesPerView: 2,
                                  spaceBetween: 16,
                                },
                                '960': {
                                  slidesPerView: 3,
                                  spaceBetween: 24,
                                },
                                '1140': {
                                  slidesPerView: 3,
                                  spaceBetween: 32,
                                },
                              }">
                    <swiper-slide v-for="item in [0,0,0,0,0,0]" :key="item">
                      <AnimatedComponent animation-enter="animate__fadeIn">
                        <ion-card class="new">
                          <img src="https://images.unsplash.com/photo-1495474472287-4d71bcdd2085?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="Miniweb" />
                          <ion-card-content>
                            <h5 class="title">Lorem Impsun Dolor</h5>
                            <h6 class="metadata color-medium">16 de Marzo de 2022</h6>
                            <p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et officiis quibusdam ratione illum quidem asperiores architecto blanditiis dolores facilis</p>
                            <ion-button shape="round" class="action">
                              <ion-label>Ver más</ion-label>
                              <ion-icon slot="end" src="/assets/img/uicons/fi-rr-arrow-small-right.svg" style="color: var(--ion-color-primary-contrast);"></ion-icon>
                            </ion-button>
                          </ion-card-content>
                        </ion-card>
                      </AnimatedComponent>
                    </swiper-slide>
                    <swiper-slide class="last-slide">
                    </swiper-slide>
                </swiper>
              </ion-col>
            </ion-row>
          </ion-grid>
        </section>-->

        <Footer></Footer>
      </div>
      
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button  class="whatsapp" href="https://api.whatsapp.com/send?phone=34693745702&text=Hola%2C%20estaba%20interesado%20en" target="_blank">
          <ion-icon src="/assets/img/uicons/fi-brands-whatsapp.svg" color="dark"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonButton, IonLabel, IonFab, IonFabButton, IonIcon, IonCard, IonCardContent, menuController, IonGrid, IonRow, IonCol, IonRippleEffect, IonItem, IonAccordionGroup, IonAccordion, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, modalController, IonList, IonThumbnail } from '@ionic/vue';
import { defineComponent } from 'vue';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import AnimatedComponent from '@/components/others/AnimatedComponent.vue';
import StarRating from 'vue-star-rating'

import { Autoplay, EffectCreative, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import Details from './Details.vue';
import { mapActions, mapState } from 'vuex';

import { Fireworks } from 'fireworks-js'



export default defineComponent({
  name: 'HomePage',
  components: { IonContent, IonPage, Header, Footer, StarRating, Swiper, SwiperSlide, AnimatedComponent,  IonButton, IonLabel, IonIcon, IonCard, IonCardContent, IonGrid, IonRow, IonCol, IonItem, IonAccordionGroup, IonAccordion, IonFab, IonFabButton, IonList, IonThumbnail, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonRippleEffect },
    data() {
    return {
      productsData: [],
      products: [],

      activeCategory: 'Novedades',
      activeSort: 'populares'
    }
  },
  computed: {
    ...mapState(['miniweb'])
  },
  methods:{
    ...mapActions(['getMiniweb']),

    async openDetails(data) {
        const modal = await modalController.create({
          component: Details,
          initialBreakpoint: 0.95,
          mode: "ios",
          handle: false,
          cssClass: "details-modal",
          componentProps: { 
            data,
          }
        });

        modal.present();
      },

    openContact(){
        menuController.open('contact-menu');
    },

    scrollToSection(section){
      const scrollContent = document.querySelector('ion-content.main-content');
      let y = document.getElementById(section).offsetTop;
      scrollContent.scrollToPoint(0, y - 64, 500)
    },

    // PRODUCTS
      // CATEGORIES
      filterProducts(){
        this.activeCategory = event.detail.value;

        this.productsData = JSON.parse(JSON.stringify(this.miniweb.products.filter(x => x.category === this.activeCategory)));
        if(this.activeCategory === 'Novedades'){
          this.productsData = JSON.parse(JSON.stringify(this.miniweb.products.sort((a, b) => {return a.order - b.order })));
        }

        this.products = [];

        this.activeSort = 'populares';
        const nextItems = this.productsData.sort((a, b) => { a.order - b.order }).slice(0, 8);
        this.products.push(...nextItems);
      },

      // SORT
      sortProducts(event){
        this.activeSort = event.detail.value;

        switch (this.activeSort) {
          case 'bajo':
            this.productsData = this.productsData.sort((a, b) => {return a.price - b.price });
          break;

          case 'alto':
            this.productsData = this.productsData.sort((a, b) => {return b.price - a.price });
          break;
                  
          default:
            console.log('populares');
            this.productsData = JSON.parse(JSON.stringify(this.miniweb.products));
            break;
        }

        this.products = [];

        const nextItems = this.productsData.sort((a, b) => {return a.order - b.order }).slice(0, 8);
        this.products.push(...nextItems);
      },

      // LOAD
      loadLazyData() {
        if(!this.miniweb){
          setTimeout(() => {
            this.loadLazyData();
          }, 1000);
        } else {
          this.productsData = JSON.parse(JSON.stringify(this.miniweb.products));
          this.products = [];

          const nextItems = this.productsData.sort((a, b) => {return a.order - b.order }).slice(0, 8);
          this.products.push(...nextItems);
        }
      },

      loadMoreData(){
          if (this.products.length < this.productsData.length) {
            const nextItems = this.productsData.slice(this.products.length, this.products.length+8);
            this.products.push(...nextItems);
          }
      },
  },
  created(){
    this.getMiniweb();
    this.loadLazyData()
  },
  mounted(){
    const container = document.querySelector('#fireworks-container');
    const fireworks = new Fireworks(container, {
      autoresize: true,
      opacity: 0.5,
      acceleration: 1.05,
      friction: 0.97,
      gravity: 1.5,
      particles: 50,
      traceLength: 3,
      traceSpeed: 10,
      explosion: 5,
      intensity: 30,
      flickering: 50,
      lineStyle: 'round',
      hue: {
        min: 0,
        max: 360
      },
      delay: {
        min: 30,
        max: 60
      },
      rocketsPoint: {
        min: 50,
        max: 50
      },
      lineWidth: {
        explosion: {
          min: 1,
          max: 3
        },
        trace: {
          min: 1,
          max: 2
        }
      },
      brightness: {
        min: 50,
        max: 80
      },
      decay: {
        min: 0.015,
        max: 0.03
      },
      mouse: {
        click: false,
        move: false,
        max: 1
      }
    });

    fireworks.start();

    setTimeout(() => {
      fireworks.updateSize();
    }, 100);


  },
  setup() {
    return { modules: [Pagination, Autoplay, Navigation, EffectCreative]};
  }
});
</script>

<style scoped>
 /* SLIDER */
  #slider .swiper-imagenes{
    padding: 0;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
  }

  .swiper-imagenes .swiper-info{
    width: 100%;
    padding: 24px;
    color: var(--ion-color-dark);
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translateX(-50%) translateY(-50%);
  }


  .swiper-imagenes .swiper-info .subtitle{
    padding: 24px 0;
    color: var(--ion-color-dark);
    font-weight: 400 !important;
  }


  .swiper-imagenes .swiper-imagen{
      display: block;
      width: 100%;
      height: auto;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      margin: 0;
      background: transparent;
      aspect-ratio: 16 / 9;
      border-radius: 24px;
  }

  .swiper-imagenes .swiper-imagen img{
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
      object-position: center;
      opacity: 0.5;
  }

  /* SERVICES */
  .services{
    margin-left: -16px;
    margin-right: -16px;
  }

  .service{
    margin: 16px;
    width: calc(100% - 32px);
    cursor: pointer;
    border-radius: 16px;
  }

  .service .image{
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: contain;
    padding: 12px;
    background: #fff;
    object-position: center;
    aspect-ratio: 1 / 1;
    border-radius: 16px;
  }


  .service .text .title{
    padding: 12px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
           line-clamp: 2; 
    -webkit-box-orient: vertical;
    color: var(--ion-color-dark);
  }

  .service .text .description{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
            line-clamp: 3; 
    -webkit-box-orient: vertical;
  }

  .service .text .price{
    color: var(--ion-color-tertiary);
    font-size: 16px;
    font-weight: bold;
  }

  .service .text .discount{
    color: var(--ion-color-primary);
    font-size: 16px;
    font-weight: bold;
    padding-right: 8px;
    text-decoration:line-through;
  }

  /* ABOUT */
  .about-image{
    width: 100%;
    height: 72vh;
    object-fit: cover;
    object-position: center;
    border-radius: 24px;
    margin-bottom: 24px;
  }

  .about-text{
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .about-paragraph:deep(p){
    padding-bottom: 16px;
  }

  .caracteristicas-container{
    margin: -16px;
  }

  /* BRANDS */
    .brands{
      display: contents;
    }

    .brands ion-row{
      margin-top: 32px;
    }

    .brand{
      width: 100%;
      padding: 24px;
      object-fit: contain;
      object-position: center;
    }

  
  /* FEATURES */
  .feature{
    margin: 16px;
    width: calc(100% - 32px);
    padding: 24px;
  }

    .feature .icon{
      width: 64px;
      height: 64px;
      margin-bottom: 24px;
      color: var(--ion-color-primary);
    }

    .feature .title{
      padding-bottom: 16px;
    }

    /* GALLERY */
    .mansory-gallery{
      max-width: 100%;
      margin: -16px;
      columns: 3 280px;
      column-gap: 16px;
    }

    .mansory-gallery .mansory-img {
        margin: 16px;
        display: inline-block; /* stops block being split across columns. Could use break-inside: avoid for modern browsers; */
        width: calc(100% - 16px);
        break-inside: avoid;
        text-align: center;
        font-weight: bold;
    }

    .mansory-gallery .mansory-img img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 24px;
    }


  /* TESTIMONIALS */
  #testimonios{
    position: relative;
  }

  .swiper-testimonials{
    border-radius: 24px;
    background: linear-gradient(45deg, rgba(43,85,140, 1) 0%, rgba(161,189,226, 1) 100%);
    width: 100%;
    padding: 136px;
  }

  .testimonial{
    max-width: 960px;
    margin: 0 auto;
    padding: 80px;
    text-align: left;
    background: var(--ion-color-light-tint);
    border-radius: 24px;
  }

  .testimonial .avatar{
    width: 80px;
    height: 80px;
    border-radius: 100%;
    margin-bottom: 24px;
    display: block;
  }

    .testimonial .avatar img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 100%;
    }

  .testimonial .comment{
    padding-bottom: 32px;
    color: var(--ion-color-dark-tint);
  }

  .testimonial .stars{
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .testimonial .provider{
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 48px;
      object-fit: contain;
      object-position: left top;
  }

  .more-testimonials{
    position: absolute;
    bottom: 44px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
  }
  
  /* FAQS */

  /* NEWS */
  .new{
    width: 100%;
    text-align: left;
  }

  .new .title{
    margin-bottom: 16px !important;
    overflow: hidden;
    text-overflow: clip;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
    -webkit-box-orient: vertical;
  }
  .new .metadata{
    margin-bottom: 16px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1; 
    -webkit-box-orient: vertical;
  }
  .new .description{
    margin-bottom: 24px !important;
        overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
    -webkit-box-orient: vertical;
  }

  .new .action{
    min-height: 40px;
  }

  .whatsapp{
    --background: #25d366 !important;
    margin: 1rem;
  }

  /* CUSTOM */
  #fireworks-container{
    height: 100vh;
    width: 100vw;
    z-index: -1;
    position: fixed;
  }

  /* RESPONSIVE */
  @media (max-width: 540px) {
    .swiper-imagenes .swiper-imagen{
        aspect-ratio: 9 / 16;
    }

    .swiper-imagenes .swiper-info{
      width: 95%;
    }

    .swiper-imagenes .swiper-info .title{
      font-size: 48px !important;
    }

    /* SERVICES */
      .order{
        margin-top: 16px;
      }

      .services{
        margin-left: -8px;
        margin-right: -8px;
      }

      .service{
        margin: 8px;
        margin-bottom: 16px;
        width: calc(100% - 16px);
      }


    /* GALLERY */
      .mansory-gallery{
        margin: -8px;
        column-gap: 8px;
      }

      .mansory-gallery .mansory-img {
          margin: 8px;
          width: 100%;
      }

    /* TESTIMONIALS */
    .swiper-testimonials{
      padding: 32px;
    }

    .testimonial{
      padding: 32px;
    }

    .more-testimonials{
      position: relative;
      bottom: -32px;
      left: 50%;
      transform: translateX(-50%);
      --ion-color-base: var(--ion-color-dark) !important;
      z-index: 999;
    }

    .more-testimonials ion-icon{
      color: var(--ion-color-dark) !important;
    }

  }
  @media (min-width: 540px) and (max-width: 720px) {

    
    .swiper-imagenes .swiper-imagen{
        aspect-ratio: 9 / 16;
    }

    .swiper-imagenes .swiper-info{
      width: 95%;
    }

    .swiper-imagenes .swiper-info .title{
      font-size: 48px !important;
    }

    .order{
      margin-top: 16px;
    }

        /* SERVICES */
      .order{
        margin-top: 16px;
      }

      .services{
        margin-left: -8px;
        margin-right: -8px;
      }

      .service{
        margin: 8px;
        margin-bottom: 16px;
        width: calc(100% - 16px);
      }


    /* GALLERY */
      .mansory-gallery{
        margin: -8px;
        column-gap: 8px;
      }

      .mansory-gallery .mansory-img {
          margin: 8px;
          width: 100%;
      }

    /* TESTIMONIALS */
    .swiper-testimonials{
      padding: 32px;
    }

    .testimonial{
      padding: 32px;
    }

    .more-testimonials{
      position: relative;
      bottom: -32px;
      left: 50%;
      transform: translateX(-50%);
      --ion-color-base: var(--ion-color-dark) !important;
      z-index: 999;
    }

    .more-testimonials ion-icon{
      color: var(--ion-color-dark) !important;
    }
  }
  

  @media (min-width: 720px) and (max-width: 960px) {
    .swiper-imagenes .swiper-imagen{
        aspect-ratio: 1 / 1;
    }

    .swiper-imagenes .swiper-info{
      width: 50%;
    }
  }

  @media (min-width: 960px) and (max-width: 1140px) {
    .swiper-imagenes .swiper-imagen{
        aspect-ratio: 1 / 1;
    }

    .swiper-imagenes .swiper-info{
      width: 50%;
    }
  }

  @media (min-width: 1140px) and (max-width: 1280px) {
    .swiper-imagenes .swiper-imagen{
        aspect-ratio: 16 / 9;
    }

    .swiper-imagenes .swiper-info{
      width: 50%;
    }
  }

  @media (min-width: 1280px) {
    .swiper-imagenes .swiper-imagen{
        aspect-ratio: 16 / 9;
    }

    .swiper-imagenes .swiper-info{
      width: 50%;
    }

  }


</style>
