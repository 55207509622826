<template>
  <ion-menu content-id="main-content" menu-id="contact-menu" class="contact-menu" :swipeGesture="false" side="end">
    <ion-content style="--background: var(--ion-color-light-tint)">
        <div class="container">
            <ion-header class="ion-no-border" id="menu-header">
            <ion-toolbar style="--background: var(--ion-color-light-tint)" class="menu-header-item">
                <h5 slot="start">Contacto</h5>
                <ion-buttons slot="end">
                    <ion-button @click="close()">
                        <ion-icon slot="icon-only" src="/assets/img/uicons/fi-rr-cross-small.svg"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
            </ion-header>

            <section id="telefono" class="mb-md">
                <ion-grid>
                    <ion-row>
                        <ion-col size="12" size-lg="6">
                            <AnimatedComponent animation-enter="animate__fadeIn">
                                <div class="contact-phone">
                                    <p class="pb-xxs">Teléfono</p>
                                    <a href="tel:693745702" target="_blank">
                                        <h4>693 74 57 02</h4>
                                    </a>
                                </div>
                            </AnimatedComponent>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </section>
            
            <section id="contacto" class="mb-md">
                <form @submit.prevent="makeContact">
                    <ion-grid>
                        <ion-row class="mb-xxs">
                            <ion-col size="12">
                                <p>Escríbenos</p>
                            </ion-col>
                        </ion-row>
                        <ion-row style="margin-left: -8px; margin-right: -8px">
                            <ion-col size="12" size-lg="6">
                                <ion-item fill="outline" ref="item0" class="box-input" :class="{ 'ion-invalid': v$.contact.firstName.$errors.length }">
                                    <ion-label position="floating" color="medium">Nombre</ion-label>
                                    <ion-input type="text" placeholder="David" v-model.trim.lazy="v$.contact.firstName.$model"></ion-input>
                                    <ion-note v-for="(error, index) of v$.contact.firstName.$errors" :key="index" slot="error">{{ error.$message }}</ion-note>
                                </ion-item>
                            </ion-col>

                            <ion-col size="12" size-lg="6">
                                <ion-item fill="outline" ref="item1" class="box-input" :class="{ 'ion-invalid': v$.contact.lastName.$errors.length }">
                                    <ion-label position="floating" color="medium">Apellidos</ion-label>
                                    <ion-input type="text"  placeholder="García Rodríguez" v-model.trim.lazy="v$.contact.lastName.$model"></ion-input>
                                    <ion-note v-for="(error, index) of v$.contact.lastName.$errors" :key="index" slot="error">{{ error.$message }}</ion-note>
                                </ion-item>
                            </ion-col>

                            <ion-col size="12" size-lg="6">
                                <ion-item fill="outline" ref="item2" class="box-input" :class="{ 'ion-invalid': v$.contact.email.$errors.length }">
                                    <ion-label position="floating" color="medium">Email</ion-label>
                                    <ion-input type="email"  placeholder="manuel.gr@gmail.com" :clear-input="true" v-model.trim.lazy="v$.contact.email.$model"></ion-input>
                                    <ion-note v-for="(error, index) of v$.contact.email.$errors" :key="index" slot="error">{{ error.$message }}</ion-note>
                                </ion-item>
                            </ion-col>
                            <ion-col size="12" size-lg="6">
                                <ion-item fill="outline" ref="item3" class="box-input" :class="{ 'ion-invalid': v$.contact.phone.$errors.length }">
                                    <ion-label position="floating" color="medium">Teléfono (opcional)</ion-label>
                                    <ion-input type="tel"  placeholder="000 000 000" :clear-input="true" v-model.trim.lazy="v$.contact.phone.$model"></ion-input>
                                    <ion-note v-for="(error, index) of v$.contact.phone.$errors" :key="index" slot="error">{{ error.$message }}</ion-note>
                                </ion-item>
                            </ion-col>
                            <ion-col size="12">
                                <ion-item fill="outline" ref="item4" class="box-textarea" :class="{ 'ion-invalid': v$.contact.message.$errors.length }">
                                    <ion-label position="floating" color="medium">Mensaje</ion-label>
                                    <ion-textarea :auto-grow="true" placeholder="Hola! estoy interesado/a en..." v-model.trim.lazy="v$.contact.message.$model"></ion-textarea>
                                    <ion-note v-for="(error, index) of v$.contact.message.$errors" :key="index" slot="error">{{ error.$message }}</ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <!--<ion-col size="12">
                                <ion-item lines="none">
                                    <ion-checkbox slot="start" color="primary" v-model.lazy="contact.privacy" name="privacy"></ion-checkbox>
                                    <ion-label text-wrap>Acepto la <a href="" target="_blank">Política de privacidad</a></ion-label>
                                </ion-item>
                                <ion-item lines="none">
                                    <ion-checkbox slot="start" color="primary" v-model.lazy="contact.personalData" name="personalData"></ion-checkbox>
                                    <ion-label text-wrap>Acepto el <a href="/" target="_blank">Consentimiento para personas usuarias de la web</a></ion-label>
                                </ion-item>
                            </ion-col>-->
                            <ion-col size="12" size-lg="6">
                                <ion-button shape="round" type="submit" expand="block" style="margin-top: 24px" :disabled="v$.contact.$invalid">
                                    <ion-label>Contactar</ion-label>
                                </ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </form>
            </section>



        </div>
    </ion-content>
  </ion-menu>
</template>


<script>
import { defineComponent } from 'vue';
import { IonMenu, IonContent, IonHeader,IonToolbar, IonButtons, IonButton, IonIcon, menuController, IonLabel, IonItem, IonInput, IonNote, IonTextarea, IonCol, IonRow, IonGrid } from '@ionic/vue';
import AnimatedComponent from '@/components/others/AnimatedComponent.vue';
import { mapActions } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required } from '@/utils/i18n-validators';
import { maxLength, minLength, email } from '@/utils/i18n-validators';

export default defineComponent({
  name: 'ContactComponent',
  components: { IonMenu, IonContent, IonHeader,IonToolbar, IonButtons, IonButton, IonIcon, AnimatedComponent, IonLabel, IonItem, IonInput, IonNote, IonTextarea, IonCol, IonRow, IonGrid },
  data() {
    return {
        contact: {
            firstName: '',
            lastName: '',
            email: '',
            message: '',
            privacy: false,
            personalData: false,
          },
    }
  },
  validations() {
    return {
      contact: {
        firstName: { required },
        lastName: { required },
        phone: { minLength: minLength(9), maxLength: maxLength(9) },
        email: { required, email },
        message: { required },
        privacy: { required },
        personalData: { },
      },
    }
  },
  methods: {
    ...mapActions(['openToast']),

    close(){
        menuController.close();
    },

    async makeContact() {
      fetch('https://us-central1-evolbe-87cdd.cloudfunctions.net/sendEmail_Contact', { method: 'POST', body: JSON.stringify({
          "web": 'pirovampiro.es',
          "firstName": this.contact.firstName,
          "lastName": this.contact.lastName,
          "message": this.contact.message,
          "email": this.contact.email,
          "sendTo": 'info@pirovampiro.es'
        })
      }).then(() => {
        this.openToast({message: 'Gracias por tu mensaje, te responderemos con la mayor brevedad posible.', color: 'success'});
      });
    }
  },
  setup() {
    return { v$: useVuelidate() };
  }
});
</script>

<style scoped>
    .contact-menu{
        --width: 100%;
        --max-width: 800px;
        --background: var(--ion-color-light-tint);
    }

    #menu-header{
        overflow: hidden;
        background: transparent;
        border-bottom: 0;
        margin: 24px 0;
    }

    .menu-header-item{
        --inner-padding-start: 0;
        --inner-padding-end: 0;
        --inner-padding-top: 0;
        --inner-padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;
        display: flex;
        align-items: center;
        height: 40px;
        overflow: visible;
        margin: 0 auto;
    }

    #mapa iframe{
        border-radius: 24px;
        width: 100%;
        height: 256px;
        background: var(--ion-color-light-shade);
    }

    .contact-phone{
        padding-right: 16px;
    }

    .contact-address{
        padding-left: 16px;
    }
    
    /* RESPONSIVE */
    @media (max-width: 540px) {
       .contact-phone{
            padding-right: 0;
            padding-bottom: 16px;
        }

        .contact-address{
            padding-left: 0;
        }
    }

    @media (min-width: 540px) and (max-width: 720px) {
         .contact-phone{
            padding-right: 0;
            padding-bottom: 16px;
        }

        .contact-address{
            padding-left: 0;
        }
    }

    @media (min-width: 720px) and (max-width: 960px) {
    }

    @media (min-width: 960px) and (max-width: 1140px) {
   
    }

    @media (min-width: 1140px) and (max-width: 1280px) {
    
    }

    @media (min-width: 1280px) {

    }
    
</style>